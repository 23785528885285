<div class="page-wrapper">
    <div class="page-header flex-row items-center place-content-between">
        <button
            class="back-button"
            mat-flat-button
            [routerLink]="['..']"
            [queryParams]="{ accountId: passedAccountId$ | async }"
            *ngIf="(showList$ | async) === false"
        >
            <fa-icon [icon]="icons.chevronLeft" size="1x"></fa-icon>
        </button>
        <h2 class="flex-auto">{{ pageTitle$ | async }}</h2>
    </div>
    <div class="page-content">
        @if (error$ | async; as error) {
            <div class="error">{{ error }}</div>
        } @else if (showList$ | async) {
            <div class="flex flex-col gap-3" *ngrxLet="reports$; let reports">
                @if (reports.length > 0) {
                    <a
                        *ngFor="let report of reports"
                        [routerLink]="[report.id]"
                        [queryParams]="{ accountId: passedAccountId$ | async }"
                        >{{ report.label }}</a
                    >
                } @else {
                    <div>No reports found</div>
                }
            </div>
        } @else {
            <div id="report"></div>
        }
    </div>
</div>
