<div id="root" class="flex flex-col h-full" [class]="strings.rootCssClass" *ngrxLet="appUser$; let appUser">
    <environment-status></environment-status>
    <header class="flex flex-row" *ngIf="!!appUser">
        <button
            class="md:hidden"
            mat-button
            type="button"
            onclick="this.blur()"
            aria-label="Toggle sidenav"
            (click)="sidenav?.toggle()"
        >
            <fa-icon [icon]="icons.bars" size="lg"></fa-icon>
        </button>
        <h1 class="flex flex-grow">
            <a routerLink="" class="hidden md:inline">{{ appTitle }}</a>
        </h1>
        <button mat-button [matMenuTriggerFor]="menu" aria-label="View profile menu">
            <fa-icon [icon]="icons.userCircle" size="lg"></fa-icon>
        </button>
        <mat-menu #menu="matMenu" class="profile-menu">
            <ng-container>
                <div class="profile clickable flex flex-row" *ngIf="appUser">
                    <img class="profile-picture" [src]="appUser.picture" />
                    <div class="profile-info" *ngIf="appUser">
                        <h4>{{ appUser.lastName }}, {{ appUser.firstName }}</h4>
                        <h5>{{ appUser.primaryRole }}</h5>
                    </div>
                </div>
            </ng-container>
            <div mat-menu-item [routerLink]="['/profile']">
                <fa-icon [icon]="icons.userCircleLight"></fa-icon>
                Manage Profile
            </div>
            <div mat-menu-item (click)="managePassword()" *ngIf="appUser.authSourceType === 'database'">
                <fa-icon [icon]="icons.lock"></fa-icon>
                Manage Password
            </div>
            <mat-divider></mat-divider>
            <div class="logout text-center px-2">
                <button class="full-width" mat-flat-button color="warn" (click)="handleLogout(); (false)">
                    Logout
                </button>
            </div>
        </mat-menu>
    </header>
    <mat-sidenav-container class="flex h-full">
        <mat-sidenav
            #sidenav
            [opened]="!!appUser && !(isHandset$ | async)"
            [mode]="(isHandset$ | async) ? 'over' : 'side'"
            [fixedInViewport]="isHandset$ | async"
        >
            <div class="flex flex-col h-full" *ngrxLet="pagePermissions$; let pagePermissions">
                <div class="profile-wrapper flex flex-row items-center place-content-between">
                    <div
                        class="profile clickable flex flex-row items-center"
                        *ngIf="appUser"
                        [routerLink]="['/profile']"
                    >
                        <img class="profile-picture" [src]="appUser.picture" />
                        <div class="profile-info" *ngIf="appUser">
                            <h4>{{ appUser.lastName }}, {{ appUser.firstName }}</h4>
                            <h5>{{ appUser.primaryRole }}</h5>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="close-menu block md:hidden"
                        onclick="this.blur()"
                        aria-label="Toggle sidenav"
                        (click)="sidenav.close()"
                    >
                        <fa-icon [icon]="icons.times" size="2x"></fa-icon>
                    </button>
                </div>

                <div class="mat-list-wrapper">
                    <mat-list *ngIf="!!appUser">
                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.dashboard">
                            <a
                                routerLink="dashboard"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Dashboard"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.tachometerAlt" [fixedWidth]="true"></fa-icon>
                                <span>Dashboard</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu">
                            <a
                                routerLink="accounts"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Accounts"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.list" [fixedWidth]="true"></fa-icon>
                                <span>Accounts</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.certificates">
                            <a
                                routerLink="certificates"
                                routerLinkActive=""
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Certificates"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.fileCertificate" [fixedWidth]="true"></fa-icon>
                                <span>Certificates</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu sub-menu" *ngIf="!!pagePermissions?.certificates">
                            <a
                                routerLink="certificates"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="View Certificates"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.fileCertificate" [fixedWidth]="true"></fa-icon>
                                <span>View</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu sub-menu" *ngIf="!!pagePermissions?.manageCertificates">
                            <a
                                routerLink="certificates/request"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Request Certificate"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.commentAlt" [fixedWidth]="true"></fa-icon>
                                <span>Request</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu sub-menu" *ngIf="!!pagePermissions?.manageCertificates">
                            <a
                                routerLink="certificates/upload"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Upload Certificate"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.upload" [fixedWidth]="true"></fa-icon>
                                <span>Upload</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.cas">
                            <a
                                routerLink="cas"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Certificate Authorities"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.lock" [fixedWidth]="true"></fa-icon>
                                <span>Certificate Authorities</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.requests">
                            <a
                                routerLink="requests"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: false }"
                                matTooltip="Requests Queue"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.clipboardList" [fixedWidth]="true"></fa-icon>
                                <span>Requests Queue</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item
                            class="sidenav-menu"
                            *ngIf="!!pagePermissions?.domains && appUser.validators && appUser.validators.length > 0"
                        >
                            <a
                                routerLink="domains"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Domains"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.globe" [fixedWidth]="true"></fa-icon>
                                <span>Domains</span>
                            </a>
                        </mat-list-item>
                        <ng-container *ngIf="!!pagePermissions?.tlsdiscovery && allowTlsDiscovery">
                            <mat-list-item class="sidenav-menu">
                                <a
                                    routerLink="tlsdiscovery/probes"
                                    routerLinkActive=""
                                    (click)="sidenav.mode === 'side' || sidenav.close()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    matTooltip="TLS Discovery"
                                    matTooltipPosition="right"
                                    [matTooltipDisabled]="!minSideNav"
                                >
                                    <fa-icon [icon]="icons.radar" [fixedWidth]="true"></fa-icon>
                                    <span>TLS Discovery</span>
                                </a>
                            </mat-list-item>

                            <mat-list-item class="sidenav-menu sub-menu">
                                <a
                                    routerLink="tlsdiscovery/probes"
                                    routerLinkActive="active"
                                    (click)="sidenav.mode === 'side' || sidenav.close()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    matTooltip="Probes"
                                    matTooltipPosition="right"
                                    [matTooltipDisabled]="!minSideNav"
                                >
                                    <fa-icon [icon]="icons.tools" [fixedWidth]="true"></fa-icon>
                                    <span>Probes</span>
                                </a>
                            </mat-list-item>
                        </ng-container>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.users">
                            <a
                                routerLink="users"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Users"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.users" [fixedWidth]="true"></fa-icon>
                                <span>Users</span>
                            </a>
                        </mat-list-item>

                        @if (reports$ | async; as reports) {
                            @if (reports.length > 0) {
                                <mat-list-item class="sidenav-menu">
                                    <a
                                        [routerLink]="['reports']"
                                        routerLinkActive=""
                                        (click)="sidenav.mode === 'side' || sidenav.close()"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        matTooltip="Reports"
                                        matTooltipPosition="right"
                                        [matTooltipDisabled]="!minSideNav"
                                    >
                                        <fa-icon [icon]="icons.report" [fixedWidth]="true"></fa-icon>
                                        <span>Reports</span>
                                    </a>
                                </mat-list-item>
                                <mat-list-item class="sidenav-menu sub-menu">
                                    <a
                                        *ngFor="let report of reports"
                                        [routerLink]="['reports', report.id]"
                                        routerLinkActive="active"
                                        (click)="sidenav.mode === 'side' || sidenav.close()"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        matTooltip="View Certificates"
                                        matTooltipPosition="right"
                                        [matTooltipDisabled]="!minSideNav"
                                    >
                                        <fa-icon [icon]="icons.report" [fixedWidth]="true"></fa-icon>
                                        <span>{{ report.label }}</span>
                                    </a>
                                </mat-list-item>
                            }
                        }
                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.logs">
                            <a
                                routerLink="logs"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Logs"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.listAlt" [fixedWidth]="true"></fa-icon>
                                <span>Logs</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.help">
                            <a
                                routerLink="help"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Help"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.question" [fixedWidth]="true"></fa-icon>
                                <span>Help</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu" *ngIf="!!pagePermissions?.admin">
                            <a
                                routerLink="admin/accounts"
                                routerLinkActive=""
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Account Administration"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.gear" [fixedWidth]="true"></fa-icon>
                                <span>Admin</span>
                            </a>
                        </mat-list-item>

                        <mat-list-item class="sidenav-menu sub-menu" *ngIf="!!pagePermissions?.admin">
                            <a
                                routerLink="admin/accounts"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Account Administration"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.accountAdmin" [fixedWidth]="true"></fa-icon>
                                <span>Accounts</span>
                            </a>
                        </mat-list-item>
                        <mat-list-item class="sidenav-menu sub-menu" *ngIf="!!pagePermissions?.admin">
                            <a
                                routerLink="admin/reports"
                                routerLinkActive="active"
                                (click)="sidenav.mode === 'side' || sidenav.close()"
                                [routerLinkActiveOptions]="{ exact: true }"
                                matTooltip="Report Administration"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!minSideNav"
                            >
                                <fa-icon [icon]="icons.report" [fixedWidth]="true"></fa-icon>
                                <span>Available Reports</span>
                            </a>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="view">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <app-loader></app-loader>
</div>
