import { AppUser, DEFAULT_PAGE_PERMISSIONS, PagePermissions } from "../models/app-user";
import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { PrimaryRole } from "../models/primary-roles";

interface AppState {
    appUser: AppUser | null | undefined;
}

export const user: MemoizedSelector<AppState, AppUser | null | undefined> = createFeatureSelector("appUser");

export const acceptedEula: MemoizedSelector<AppState, boolean | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        return appUser ? appUser.acceptedEula : undefined;
    },
);

export const pagePermissions: MemoizedSelector<AppState, PagePermissions | null | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        if (!appUser) {
            // This will return either null or undefined (whatever appUser is)
            return appUser;
        }
        const pagePermissions: PagePermissions = { ...DEFAULT_PAGE_PERMISSIONS };
        if (appUser.primaryRole === PrimaryRole.UNKNOWN || !appUser.primaryRole) {
            return pagePermissions;
        }
        pagePermissions.domains = true;
        pagePermissions.logs = true;
        pagePermissions.tlsdiscovery =
            appUser.primaryRole === PrimaryRole.SYSTEM_ADMIN ||
            appUser.primaryRole === PrimaryRole.SYSTEM_AUDITOR ||
            appUser.primaryRole === PrimaryRole.ACCOUNT_AUDITOR ||
            appUser.primaryRole === PrimaryRole.ACCOUNT_ADMIN;
        if (appUser.primaryRole === PrimaryRole.DOMAIN_VALIDATOR) {
            return pagePermissions;
        }
        pagePermissions.certificates = true;
        pagePermissions.manageCertificates =
            appUser.systemRoles.admin ||
            appUser.accountAnyRoles.admin ||
            appUser.organizationAnyRoles.admin ||
            appUser.organizationAnyRoles.requestor;
        pagePermissions.cas = true;
        pagePermissions.requests = true;
        pagePermissions.dashboard = true;
        pagePermissions.users =
            appUser.systemRoles.admin ||
            appUser.systemRoles.auditor ||
            appUser.accountAnyRoles.admin ||
            appUser.accountAnyRoles.auditor ||
            appUser.organizationAnyRoles.admin ||
            appUser.organizationAnyRoles.auditor;
        pagePermissions.admin = appUser.systemRoles.admin;

        return pagePermissions;
    },
);

export const canModifyProbes: MemoizedSelector<AppState, boolean> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        if (!appUser) {
            return false;
        }
        return appUser.primaryRole === PrimaryRole.SYSTEM_ADMIN || appUser.primaryRole === PrimaryRole.ACCOUNT_ADMIN;
    },
);

export const firstAccountId: MemoizedSelector<AppState, string | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        if (appUser?.accountRoles) {
            if ("2f9b9847-4fbb-4d70-af68-d5fdc475a77d" in appUser.accountRoles) {
                return "2f9b9847-4fbb-4d70-af68-d5fdc475a77d";
            }
            return Object.keys(appUser.accountRoles)[0];
        }
        return undefined;
    },
);

export const onlyAccountId: MemoizedSelector<AppState, string | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        if (appUser?.accountRoles && Object.keys(appUser.accountRoles).length === 1) {
            return Object.keys(appUser.accountRoles)[0];
        }
        return undefined;
    },
);
