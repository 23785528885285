import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MaterialModule } from "./material.module";
import { LayoutModule } from "@angular/cdk/layout";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { EnvironmentStatusModule, ENVIRONMENT_STATUS_CONFIG } from "./modules/shared/environment-status";
import { AppUserModule } from "./modules/shared/app-user";
import { SHOW_LOADING_FOR_GET, LoadingStatusModule } from "./modules/shared/loading-status";
import { AppComponent, IdleSnackbarComponent } from "./components/app/app.component";
import { DialogsModule } from "./modules/shared/dialogs";
import { ApiModule, Configuration, ConfigurationParameters } from "@hydrantid/acm-client";
import { AUTH_CONFIG } from "./modules/shared/app-user/tokens";
import { LetDirective } from "@ngrx/component";
import { AccountsModule } from "./modules/shared/accounts";
import { PoliciesModule } from "./modules/shared/policies";
import { AppProfilesModule } from "./modules/profiles";
import { NgIdleModule } from "@ng-idle/core";
import { LogoutComponent } from "./components/logout/logout.component";
import { DialogManagePasswordComponent } from "./components/dialog-manage-password/dialog-manage-password.component";
import { DialogCertificateStatusComponent } from "./components/dialog-certificate-status/dialog-certificate-status.component";
import { NgxMaskModule } from "ngx-mask";
import { LoginComponent } from "./components/login/login.component";
import { environment } from "../configuration";
import { UsersModule } from "./modules/shared/users";
import { CertificateAuthoritiesModule } from "./modules/shared/certificate-authorities";
import { CommonModule } from "@angular/common";
import { ReportEmbedComponent } from "./components/report-embed/report-embed.component";

export function apiConfigFactory(): Configuration {
    const params: ConfigurationParameters = {
        // set configuration parameters here.
        basePath: environment.apiRoot,
    };
    return new Configuration(params);
}

class EnvironmentStatusProvider {
    fileUrl = "";
    envName = "";

    constructor() {
        this.fileUrl = environment.statusUrl;
        this.envName = environment.environmentName;
    }
}

class AuthConfigProvider {
    connection = "";

    constructor() {
        this.connection = environment.authConnectionDefault.connection;
    }
}

class ShowLoadingForGetProvider {
    showLoadingForGet = false;

    constructor() {
        this.showLoadingForGet = environment.showLoadingForGet;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        IdleSnackbarComponent,
        LogoutComponent,
        DialogManagePasswordComponent,
        DialogCertificateStatusComponent,
        LoginComponent,
        ReportEmbedComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        ApiModule,
        ApiModule.forRoot(apiConfigFactory),
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        FontAwesomeModule,
        MaterialModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        LoadingStatusModule,
        EnvironmentStatusModule,
        AccountsModule,
        PoliciesModule,
        CertificateAuthoritiesModule,
        AppProfilesModule,
        AppUserModule,
        UsersModule,
        DialogsModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            //logOnly: environment.production, // Restrict extension to log-only mode
        }),
        LetDirective,
        NgIdleModule.forRoot(),
        NgxMaskModule.forRoot(),
    ],
    providers: [
        { provide: ENVIRONMENT_STATUS_CONFIG, useClass: EnvironmentStatusProvider },
        { provide: AUTH_CONFIG, useClass: AuthConfigProvider },
        { provide: SHOW_LOADING_FOR_GET, useClass: ShowLoadingForGetProvider },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
