import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import {
    AccountConfigurationAuth0Minimal,
    LogsService,
    Report,
    ReportsService,
    UsersService,
} from "@hydrantid/acm-client";
import { tap } from "rxjs/operators";
import { environment } from "../../../configuration";
import { AzureEndpoint } from "../../modules/accounts/components/models/azure-endpoint";
import { Strings } from "../../../strings/strings";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class CachedDataService {
    private subscriptionTypes: Observable<string[]> | undefined;
    private connectionInfo: Record<string, AccountConfigurationAuth0Minimal> = {};
    private azureEndpoints: ReplaySubject<AzureEndpoint[]> | undefined;
    private reportsByAccountID: Record<string, ReplaySubject<Report[]>> = {};

    constructor(
        private logsService: LogsService,
        private usersService: UsersService,
        private httpClient: HttpClient,
        private reportsService: ReportsService,
    ) {}

    get subscriptionTypes$(): Observable<string[]> {
        if (!this.subscriptionTypes) {
            const subscriptionTypes: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
            this.logsService.logsSubscriptionTypesGet().subscribe((results) => subscriptionTypes.next(results));
            this.subscriptionTypes = subscriptionTypes.asObservable();
        }
        return this.subscriptionTypes;
    }

    getConnectionInfoForSlug(slug?: string): Observable<AccountConfigurationAuth0Minimal> {
        if (slug === undefined || slug === "default") {
            return of(environment.authConnectionDefault);
        }
        if (slug in this.connectionInfo) {
            return of(this.connectionInfo[slug]);
        }
        return this.usersService.usersConnectionSlugGet(slug).pipe(
            tap((connectionInfo) => {
                this.connectionInfo[slug] = connectionInfo;
            }),
        );
    }

    getReportsForAccount(accountID: string): Observable<Report[]> {
        if (!(accountID in this.reportsByAccountID)) {
            this.reportsByAccountID[accountID] = new ReplaySubject<Report[]>(1);
            this.reportsService.listReports(accountID).subscribe((reports) => {
                this.reportsByAccountID[accountID].next(reports);
            });
        }
        return this.reportsByAccountID[accountID].asObservable();
    }

    get azureEndpoints$(): Observable<AzureEndpoint[]> {
        if (!this.azureEndpoints) {
            this.azureEndpoints = new ReplaySubject<AzureEndpoint[]>(1);
            this.httpClient.get(`${Strings.endpointsUrl}`).subscribe((result) => {
                this.azureEndpoints!.next(result as AzureEndpoint[]);
            });
        }
        return this.azureEndpoints.asObservable();
    }
}
