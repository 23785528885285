import { InjectionToken } from "@angular/core";
import { AccountConfigurationAuth0Minimal } from "@hydrantid/acm-client";

export interface Configuration {
    environmentName: string;
    statusUrl: string;
    apiRoot: string;
    wsRoot: string;
    appName: string;
    appTitleSuffix: string;
    production: boolean;
    releaseName: string;
    sentryDsn: string;
    sentryEnabled: boolean;
    sentryShowDialog: boolean;
    showLoadingForGet: boolean;
    authDomain: string;
    authClientId: string;
    authConnectionDefault: AccountConfigurationAuth0Minimal;
    inactivityTime: number;
    timeoutWarning: number;
    timeoutStatusMilliseconds: number;
    maxCertDownload: number;
    maxLogDownload: number;
    allowRevocationChangeOnPublicCerts: boolean;
    totalAllowedCredentials: number;
    allowTlsDiscovery: boolean;
    showAccountLoggingConfig: boolean;
}

export const configurationToken = new InjectionToken("Configuration");
const DEFAULT_ENVIRONMENT: Configuration = {
    environmentName: "unknown",
    statusUrl: "",
    apiRoot: "",
    wsRoot: "",
    appName: "ACM UI",
    appTitleSuffix: "",
    production: true,
    releaseName: "acm-ui",
    sentryDsn: "",
    sentryEnabled: false,
    sentryShowDialog: false,
    showLoadingForGet: true,
    authDomain: "",
    authClientId: "",
    authConnectionDefault: { name: "", connection: "" },
    inactivityTime: 28 * 60,
    timeoutWarning: 2 * 60,
    timeoutStatusMilliseconds: 1000,
    maxCertDownload: 1000,
    maxLogDownload: 1000,
    allowRevocationChangeOnPublicCerts: false,
    totalAllowedCredentials: 10,
    allowTlsDiscovery: false,
    showAccountLoggingConfig: true,
};

export let environment: Configuration = DEFAULT_ENVIRONMENT;

export function setEnvironment(_environment: Configuration): void {
    environment = { ...DEFAULT_ENVIRONMENT, ..._environment };
}
