import { BaseStrings } from "./base_strings";

export const Strings: BaseStrings = {
    appLongName: "Account Certificate Manager",
    appShortName: "ACM",
    rootCssClass: "acm",
    supportEmail: "acmsupport@hydrantid.com",
    helpDocuments: {
        pdf: {
            url: "https://help.hydrantid.com/HydrantID_ACM_Manual.pdf",
            label: "View PDF",
        },
        html: {
            url: "https://help.hydrantid.com/",
            label: "View website",
        },
        video: {
            url: "https://hydrantid.com/technical-solutions-tutorials",
            label: "View Videos",
        },
    },
    chartColors: [
        {
            backgroundColor: "#b3e7f4",
            borderColor: "#0094ca",
            pointBackgroundColor: "#00aed9",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#00aed9",
        },
    ],
    endpointsUrl: "https://help.hydrantid.com/intune/azure_endpoints.json",
};
